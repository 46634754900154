import { Provider } from 'react-redux'
import {
    isRunningInWebview,
    store,
    ThriveApplication
} from '@thriveglobal/thrive-web-core'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Router from './routes'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)

export default function Root(props) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const navbarHeight = isMobile
        ? isRunningInWebview()
            ? 'var(--navbar-height-small-native)'
            : 'var(--navbar-height-small)'
        : 'var(--navbar-height)'

    return (
        <Box
            sx={{
                '& #thrive-application-main-content': {
                    height: `calc(100vh - ${navbarHeight})`,
                    minHeight: `calc(100vh - ${navbarHeight})`
                }
            }}
        >
            <ThriveApplication
                messagesLoaders={[loadi18n]}
                apolloClientOptions={{
                    cacheOptions: {},
                    name: 'thrive-web-guide'
                }}
                disableMaxWidth
            >
                <Provider store={store}>
                    <Router />
                </Provider>
            </ThriveApplication>
        </Box>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
