import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const ChatPage = lazy(() => import('../pages/Chat'))
const OnboardingPage = lazy(() => import('../pages/Onboarding'))
const HomePage = lazy(() => import('../pages/Home'))

enum ROUTES {
    HOME = '/',
    CHAT = '/guide/chat',
    ONBOARDING = '/guide/onboarding',
    HOMEPAGE = '/guide/home'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.CHAT}
                            component={ChatPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.ONBOARDING}
                            component={OnboardingPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.HOMEPAGE}
                            component={HomePage}
                        />
                        <ProtectedRoute
                            path={ROUTES.HOME}
                            component={HomePage}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
